import { Slide } from "@/types/queries";
import { usePage } from "@inertiajs/react";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

type Props = {
    slides: Slide[];
};
export default function ({ slides }: Props) {
    const {
        props: { currentLang },
    }: any = usePage();
    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            // spaceBetween={0}
            autoplay
            slidesPerView={1}
            dir={`${currentLang === "ar" ? "ltr" : "rtl"}`}
            navigation={false}
            pagination={{ clickable: true }}
            className="rounded-3xl"
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
        >
            {slides.map((s: Slide, i: number) => (
                <SwiperSlide
                    key={i}
                    className="rounded-3xl w-full max-h-[550px] md:max-h-[550px]"
                >
                    <img
                        // width="1000"
                        // height="500"
                        src={s.image}
                        // placeholder='blur'
                        loading="lazy"
                        alt={`${import.meta.env.VITE_APP_NAME}`}
                        className="w-full h-auto aspect-video object-contain relative rounded-2xl"
                    />
                    <div className="absolute top-0 w-full h-full bg-[#4c4c4c29] text-white px-10 py-5 flex items-end">
                        <a
                            target="_blank"
                            className="mb-10 px-5 w-full text-end"
                            href={s.url ? s.url : "#"}
                        >
                            <h1 className="text-md lg:text-2xl xl:text-4xl capitalize">
                                {s.name}
                            </h1>
                            <h4 className="text-sm lg:text-xl xl:text-2xl mt-1 lg:mt-6 capitalize">
                                {s.description}
                            </h4>
                        </a>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
